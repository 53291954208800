import React from "react"
import { graphql, useStaticQuery} from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage} from "gbimage-bridge"


const GbiBridged = () => {
    const {backgroundImage123} = useStaticQuery (
        graphql`
        query {
            backgroundImage123: file(relativePath: {eq: "telesan.jpg"}) {
                childImageSharp {
                  gatsbyImageData(
                    width: 2000, 
                    quality: 50, 
                    webpOptions: {quality: 70})
                }
            }
        }
    `
    )

    const pluginImage = getImage(backgroundImage123)
    return (
       <BgImage image ={pluginImage} className="masthead">
           <h1>This is the BgImage</h1>
           test
       </BgImage>
    )

}

export default GbiBridged